import { FeatureFlag } from '@mono/data/feature-flags';
import { logAndReturnError } from '@mono/data/logging';
import { OneTimeTrigger } from '@mono/data/one-time-triggers';
import useSWR from 'swr';

import { exposeUserMeta } from './exposeUserMeta';

export interface AnonymousUser {
  auth_token: string;
  experiments: Record<string, string>;
  features: FeatureFlag[];
  oneTimeTriggers: OneTimeTrigger[];
  location: {
    in_eu: boolean;
    geo_country: string;
  };
  upgradeUrl: string;
  purchasablePlans: PurchasablePlan[];
  canAccessCareerPaths: boolean;
  isBusinessUser: boolean;
  isBusinessSsoUser: boolean;
  isSkillsoftUser: boolean;
  externalBusinessSite: string;
  created_at: string; // ISO-8601
  showTrialCTA: boolean;
}

export type ColorModeType = 'light' | 'dark' | 'system' | undefined;

export interface UserAppearance {
  color_mode?: ColorModeType;
}

export interface LoggedInUser extends AnonymousUser {
  email: string;
  id: string;
  is_pro: boolean;
  isPaused: boolean;
  isPlusUser: boolean;
  jwt: string;
  name: string;
  profile_image_url: string;
  roles: UserRoles;
  username: string;
  isAccountManager: boolean;
  isBusinessAdmin: boolean;
  isStudent?: boolean;
  hideBusinessAccount: boolean;
  hasNewSkuSubscription?: boolean;
  appearance?: UserAppearance;
  optedOutExternalTracking: boolean;
  showMoneybackCTA: boolean;
  entitlements?: string[];
  inBusinessTrial: boolean;
  isBusinessUser: boolean;
}

export type PurchasablePlan = {
  currencySymbol: string;
  link: string;
  monthlyPrice: string;
  monthlyPriceWithoutSavings?: string;
  planId: string;
  recommended?: boolean;
  title: string;
};

export type NotificationResponse = {
  campaign_name: string;
  description: string;
  href: string;
  id: string;
  img?: {
    type: string;
    slug: string;
  };
  status: 0 | 1;
  time: string;
  type: string;
};

export type LoggedInUserResponseData = LoggedInUser & {
  notifications: NotificationResponse[];
};

export type UserRoles = {
  admin?: boolean;
  advisor?: boolean;
  author?: boolean;
  customer_service?: boolean;
  forum_moderator?: boolean;
  staff?: boolean;
};

export function userIsLoggedIn<
  L extends LoggedInUser = LoggedInUser,
  A extends AnonymousUser = AnonymousUser
>(user: A | L): user is L {
  return 'jwt' in user;
}

export type UserResponseData =
  | AnonymousUser
  | LoggedInUserResponseData
  | undefined;

export const userDataEndpoint =
  process.env.NEXT_PUBLIC_USER_DATA_URL ?? `/users/web`;

export const fetchUser = async (endpoint = userDataEndpoint) => {
  const res = await fetch(endpoint, {
    credentials: 'include',
    mode: 'cors',
  });
  if (!res.ok) {
    const msg = (await res.json()) as string;
    const err = new Error(`Failed to fetch user data: ${msg}`);
    throw logAndReturnError(err);
  }
  return res.json() as Promise<UserResponseData>;
};

export const useUser = (endpoint = userDataEndpoint) => {
  const { data } = useSWR<UserResponseData>(endpoint, fetchUser, {
    revalidateOnFocus: false,
  });

  if (data) exposeUserMeta(data);

  return data;
};

export const useAdmin = () => {
  const user = useUser();
  return user && userIsLoggedIn(user) && user?.roles?.admin;
};

export const useSkillsoftUser = () => {
  const user = useUser();
  return Boolean(user && userIsLoggedIn(user) && user.isSkillsoftUser);
};

export const useUserAppearance = () => {
  const user = useUser();

  if (!user || !userIsLoggedIn(user)) return undefined;
  return user?.appearance;
};
