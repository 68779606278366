import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import {
  AppHeaderAction,
  AppHeaderCatalogDropdownItem,
  StyledDropdown,
} from '../../shared';
import { AppHeaderCatalogSection } from '../AppHeaderCatalogSection';
import { AppHeaderDropdownProvider } from '../AppHeaderDropdownProvider';
import { AppHeaderDropdownNavButton } from '../AppHeaderNavButton/AppHeaderDropdownNavButton';
import { useAppHeaderContext } from '../AppHeaderProvider';

export type AppHeaderCatalogDropdownProps = AppHeaderAction & {
  item: AppHeaderCatalogDropdownItem;
  isAnon: boolean;
};

export const AppHeaderCatalogDropdown: React.FC<
  AppHeaderCatalogDropdownProps
> = ({ action, item, isAnon }) => {
  const { text } = item;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { lastOpenedDropdown, setLastOpenedDropdown } = useAppHeaderContext();

  const focusButton = () => {
    buttonRef?.current?.focus();
  };

  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const handleOnClick = (event: React.MouseEvent) => {
    toggleIsOpen();

    if (!isOpen) {
      action(event, item);
      if (setLastOpenedDropdown) {
        setLastOpenedDropdown(text);
      }
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    focusButton();
  }, []);

  useEffect(() => {
    if (lastOpenedDropdown !== text && isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastOpenedDropdown]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | Event) {
      const container = containerRef?.current;
      const button = buttonRef?.current;
      if (
        isOpen &&
        container &&
        !container.contains(event.target as Node) &&
        button &&
        !button.contains(event.target as Node)
      ) {
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('blur', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('blur', handleClickOutside);
    };
  }, [containerRef, handleClose, isOpen]);

  return (
    <AppHeaderDropdownProvider handleClose={handleClose}>
      <AppHeaderDropdownNavButton
        buttonRef={buttonRef}
        handleOnClick={handleOnClick}
        isOpen={isOpen}
        title={text}
      />
      <StyledDropdown
        style={{
          top: '3.5rem',
          minWidth: '64rem',
          left: isAnon ? '-9rem' : '-14rem',
        }}
        initial={{ borderWidth: 0, height: 0 }}
        animate={{
          borderWidth: isOpen ? 1 : 0,
          height: isOpen ? 'fit-content' : 0,
        }}
        transition={{ duration: 0.175 }}
        aria-hidden={!isOpen}
        data-testid="catalog-menu-dropdown"
      >
        <AppHeaderCatalogSection
          action={action}
          item={item}
          ref={containerRef}
          id={`menu-container${item.text}`}
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
        />
      </StyledDropdown>
    </AppHeaderDropdownProvider>
  );
};
